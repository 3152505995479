body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.playerlist > li:hover {
  background-color: #3498db;
  color: #ffffff;    
}

.playerlist {
  max-height: 360px;
  margin-bottom: 10px;
  overflow:scroll;
  -webkit-overflow-scrolling: touch;  
}

.bg-blue {
  background-color: #33334C;
}